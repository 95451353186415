<template>
  <div>
    <vm-table ref="vmTable" :v-loading="loading" :filter.sync="search" url="mODSalesOrders">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <table-column prop="salesOrderNo" :label="$t('maintWorkOrder.salesOrderNo')" align="center">
        <template #default="scope">
          <span style="color: blue" @click="goDetail(scope.row.id)">
            {{ scope.row.salesOrderNo }}
          </span>
        </template>
      </table-column>
      <table-column prop="mobile" :label="$t('maintWorkOrder.phone')" align="center"></table-column>
      <table-column prop="contact" :label="$t('maintWorkOrder.contacts')" align="center"></table-column>
      <table-column prop="creatorName" :label="$t('common.creator')" align="center"></table-column>
      <table-column prop="status" :label="$t('common.status')" align="center">
        <template #default="scope">
          <span v-if="scope.row.status === 10">
            <el-tag type="info">{{ $t('common.notSubmit') }}</el-tag>
          </span>
          <span v-else>
            <el-tag type="success">{{ $t('common.submit') }}</el-tag>
          </span>
        </template>
      </table-column>
    </vm-table>
    <m-o-d-sales-order-detail ref="detail" @save-success="getList(-1)"></m-o-d-sales-order-detail>
  </div>
</template>
<script>

import MODSalesOrderDetail from "@/views/modSalesOrder/MODSalesOrderDetail";
export default {
  components: { MODSalesOrderDetail },
  data() {
    return {
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    goDetail(id) {
      this.$refs.detail.open(id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
